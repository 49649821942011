import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Kaspersky Internet Security 2013",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2013/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_Kaspersky13.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_Kaspersky13.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Kaspersky 2013' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/Kaspersky_2013/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2013/' crumbLabel="Kaspersky" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-an-exception-rule-inside-your-kaspersky-2013",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-an-exception-rule-inside-your-kaspersky-2013",
        "aria-label": "set an exception rule inside your kaspersky 2013 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set an Exception rule inside your Kaspersky 2013`}</h2>
    <p>{`In some cases, it can be that the virus scanner Kaspersky is blocking the video stream of the camera. Actually it blocks the ActiveX and Quicktime Plugin from receiving the cameras video stream. In this case you will need to set an exception in Kaspersky so it does not block the camera any more.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3fbcf66b929f6c1457084d58685aee71/6aca1/Kaspersky_Internet_Security_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAVOpOPBuFiv/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQISFAMT/9oACAEBAAEFAvRXikippmaZmnof/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAET/9oACAEDAQE/AaIsj//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAcEAABAwUAAAAAAAAAAAAAAAAAETGhAhASIUH/2gAIAQEABj8CxTqGqUs8DwPB/8QAHRAAAgIBBQAAAAAAAAAAAAAAAAERIVExYXGhwf/aAAgBAQABPyFrvuQbVcBo8mj4GykYuo//2gAMAwEAAgADAAAAEPzf/8QAGREBAAIDAAAAAAAAAAAAAAAAAQARIWGB/9oACAEDAQE/EAm3PCaGf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QH//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQVFhkXH/2gAIAQEAAT8Q2GWLWqe/yNFJxfD5Erv6m6r7eAgQpszHEbmhOmH/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3fbcf66b929f6c1457084d58685aee71/e4706/Kaspersky_Internet_Security_01_EN.avif 230w", "/en/static/3fbcf66b929f6c1457084d58685aee71/d1af7/Kaspersky_Internet_Security_01_EN.avif 460w", "/en/static/3fbcf66b929f6c1457084d58685aee71/69c29/Kaspersky_Internet_Security_01_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3fbcf66b929f6c1457084d58685aee71/a0b58/Kaspersky_Internet_Security_01_EN.webp 230w", "/en/static/3fbcf66b929f6c1457084d58685aee71/bc10c/Kaspersky_Internet_Security_01_EN.webp 460w", "/en/static/3fbcf66b929f6c1457084d58685aee71/c1dc5/Kaspersky_Internet_Security_01_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3fbcf66b929f6c1457084d58685aee71/e83b4/Kaspersky_Internet_Security_01_EN.jpg 230w", "/en/static/3fbcf66b929f6c1457084d58685aee71/e41a8/Kaspersky_Internet_Security_01_EN.jpg 460w", "/en/static/3fbcf66b929f6c1457084d58685aee71/6aca1/Kaspersky_Internet_Security_01_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3fbcf66b929f6c1457084d58685aee71/6aca1/Kaspersky_Internet_Security_01_EN.jpg",
              "alt": "Kaspersky 2013",
              "title": "Kaspersky 2013",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open the program Kaspersky by clicking the Kaspersky symbol on the right side of the windows task bar. Another way is to open the start menu and to choose the program directly. Once opened please click Settings on the upper right side.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5796e2f365c8ef4e14addfe28972687d/6aca1/Kaspersky_Internet_Security_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQADBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAezoIxH/xAAXEAADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEBAAEFAldRSn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAACAwEAAAAAAAAAAAAAAAAAEQEhUbH/2gAIAQEAAT8hqvhECE0TRD//2gAMAwEAAgADAAAAEFPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBAAAgICAwAAAAAAAAAAAAAAAREAMUFhUZHB/9oACAEBAAE/EHsYWk9glZe4QGIQhU5UDbdQkzP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5796e2f365c8ef4e14addfe28972687d/e4706/Kaspersky_Internet_Security_02_EN.avif 230w", "/en/static/5796e2f365c8ef4e14addfe28972687d/d1af7/Kaspersky_Internet_Security_02_EN.avif 460w", "/en/static/5796e2f365c8ef4e14addfe28972687d/69c29/Kaspersky_Internet_Security_02_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5796e2f365c8ef4e14addfe28972687d/a0b58/Kaspersky_Internet_Security_02_EN.webp 230w", "/en/static/5796e2f365c8ef4e14addfe28972687d/bc10c/Kaspersky_Internet_Security_02_EN.webp 460w", "/en/static/5796e2f365c8ef4e14addfe28972687d/c1dc5/Kaspersky_Internet_Security_02_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5796e2f365c8ef4e14addfe28972687d/e83b4/Kaspersky_Internet_Security_02_EN.jpg 230w", "/en/static/5796e2f365c8ef4e14addfe28972687d/e41a8/Kaspersky_Internet_Security_02_EN.jpg 460w", "/en/static/5796e2f365c8ef4e14addfe28972687d/6aca1/Kaspersky_Internet_Security_02_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5796e2f365c8ef4e14addfe28972687d/6aca1/Kaspersky_Internet_Security_02_EN.jpg",
              "alt": "Kaspersky 2013",
              "title": "Kaspersky 2013",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: In the window General protection settings please select the setting Web Anti-Virus on the left side.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f06a81f2c59785d1f52be1b45797ece0/6aca1/Kaspersky_Internet_Security_03_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAezrFgH/xAAXEAADAQAAAAAAAAAAAAAAAAAAAREg/9oACAEBAAEFAlilP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABcQAQEBAQAAAAAAAAAAAAAAAAERACH/2gAIAQEAAT8hanRMHNMSS6NG/9oADAMBAAIAAwAAABCDD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQABBAMAAAAAAAAAAAAAAAEAETFBgSFRcf/aAAgBAQABPxDYFUp5eE5bIgxLpw7SAlKxTM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f06a81f2c59785d1f52be1b45797ece0/e4706/Kaspersky_Internet_Security_03_EN.avif 230w", "/en/static/f06a81f2c59785d1f52be1b45797ece0/d1af7/Kaspersky_Internet_Security_03_EN.avif 460w", "/en/static/f06a81f2c59785d1f52be1b45797ece0/69c29/Kaspersky_Internet_Security_03_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f06a81f2c59785d1f52be1b45797ece0/a0b58/Kaspersky_Internet_Security_03_EN.webp 230w", "/en/static/f06a81f2c59785d1f52be1b45797ece0/bc10c/Kaspersky_Internet_Security_03_EN.webp 460w", "/en/static/f06a81f2c59785d1f52be1b45797ece0/c1dc5/Kaspersky_Internet_Security_03_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f06a81f2c59785d1f52be1b45797ece0/e83b4/Kaspersky_Internet_Security_03_EN.jpg 230w", "/en/static/f06a81f2c59785d1f52be1b45797ece0/e41a8/Kaspersky_Internet_Security_03_EN.jpg 460w", "/en/static/f06a81f2c59785d1f52be1b45797ece0/6aca1/Kaspersky_Internet_Security_03_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f06a81f2c59785d1f52be1b45797ece0/6aca1/Kaspersky_Internet_Security_03_EN.jpg",
              "alt": "Kaspersky 2013",
              "title": "Kaspersky 2013",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: In the Web Anti-Virus settings please click on Settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/82e7201f6de410b7ea547aa9a52f2f97/41099/Kaspersky_Internet_Security_04_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7FtJjJIrIBX/xAAYEAEBAAMAAAAAAAAAAAAAAAABEAAhMf/aAAgBAQABBQLeAwj2/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAT8hjWUQXR1HjZYj/9oADAMBAAIAAwAAABD/AMi8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQIBAT8QI//EAB0QAAIBBAMAAAAAAAAAAAAAAAABESExQVFhcYH/2gAIAQEAAT8QVEot2IJJ8tjMhjKNcEraK/BYK5l2f//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/82e7201f6de410b7ea547aa9a52f2f97/e4706/Kaspersky_Internet_Security_04_EN.avif 230w", "/en/static/82e7201f6de410b7ea547aa9a52f2f97/d1af7/Kaspersky_Internet_Security_04_EN.avif 460w", "/en/static/82e7201f6de410b7ea547aa9a52f2f97/df5cf/Kaspersky_Internet_Security_04_EN.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/82e7201f6de410b7ea547aa9a52f2f97/a0b58/Kaspersky_Internet_Security_04_EN.webp 230w", "/en/static/82e7201f6de410b7ea547aa9a52f2f97/bc10c/Kaspersky_Internet_Security_04_EN.webp 460w", "/en/static/82e7201f6de410b7ea547aa9a52f2f97/b0a15/Kaspersky_Internet_Security_04_EN.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/82e7201f6de410b7ea547aa9a52f2f97/e83b4/Kaspersky_Internet_Security_04_EN.jpg 230w", "/en/static/82e7201f6de410b7ea547aa9a52f2f97/e41a8/Kaspersky_Internet_Security_04_EN.jpg 460w", "/en/static/82e7201f6de410b7ea547aa9a52f2f97/41099/Kaspersky_Internet_Security_04_EN.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/82e7201f6de410b7ea547aa9a52f2f97/41099/Kaspersky_Internet_Security_04_EN.jpg",
              "alt": "Kaspersky 2013",
              "title": "Kaspersky 2013",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Please click on Trusted URLs on the upper right and then select Add as shown below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51722ce444f699487afeffcd23dc8134/70ebb/Kaspersky_Internet_Security_05_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHYkGwj/8QAFRABAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQEAAQUCj//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAAMf/aAAgBAQAGPwKqr//EABcQAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQEAAT8h0LCkP//aAAwDAQACAAMAAAAQBD//xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAEDAQE/EKdNP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCsf//EABoQAQEAAgMAAAAAAAAAAAAAAAERADEhcYH/2gAIAQEAAT8QtTMupiVFXrE538z/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51722ce444f699487afeffcd23dc8134/e4706/Kaspersky_Internet_Security_05_EN.avif 230w", "/en/static/51722ce444f699487afeffcd23dc8134/7bd34/Kaspersky_Internet_Security_05_EN.avif 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51722ce444f699487afeffcd23dc8134/a0b58/Kaspersky_Internet_Security_05_EN.webp 230w", "/en/static/51722ce444f699487afeffcd23dc8134/a3432/Kaspersky_Internet_Security_05_EN.webp 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51722ce444f699487afeffcd23dc8134/e83b4/Kaspersky_Internet_Security_05_EN.jpg 230w", "/en/static/51722ce444f699487afeffcd23dc8134/70ebb/Kaspersky_Internet_Security_05_EN.jpg 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51722ce444f699487afeffcd23dc8134/70ebb/Kaspersky_Internet_Security_05_EN.jpg",
              "alt": "Kaspersky 2013",
              "title": "Kaspersky 2013",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Please type in the IP address of your camera. It is very important to set a star character (*) before and after the IP address.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/41099/Kaspersky_Internet_Security_06_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9euuxxUJwKB//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQEAAQUCpS6+jEf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQAf/aAAgBAwEBPwEh/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwIf/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAITFR/9oACAEBAAE/IdUYOigxwTE//9oADAMBAAIAAwAAABA/2ML/xAAYEQACAwAAAAAAAAAAAAAAAAAAARAhMf/aAAgBAwEBPxCo0M//xAAWEQEBAQAAAAAAAAAAAAAAAAAQARH/2gAIAQIBAT8Qwp//xAAcEAACAgMBAQAAAAAAAAAAAAAAARExIUFRcfD/2gAIAQEAAT8QimSL+0JjCQdNMdRzoldRy4UKr0pP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52e58516e570a3da6c7bbd0c77a1c41a/e4706/Kaspersky_Internet_Security_06_EN.avif 230w", "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/d1af7/Kaspersky_Internet_Security_06_EN.avif 460w", "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/df5cf/Kaspersky_Internet_Security_06_EN.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/52e58516e570a3da6c7bbd0c77a1c41a/a0b58/Kaspersky_Internet_Security_06_EN.webp 230w", "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/bc10c/Kaspersky_Internet_Security_06_EN.webp 460w", "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/b0a15/Kaspersky_Internet_Security_06_EN.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52e58516e570a3da6c7bbd0c77a1c41a/e83b4/Kaspersky_Internet_Security_06_EN.jpg 230w", "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/e41a8/Kaspersky_Internet_Security_06_EN.jpg 460w", "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/41099/Kaspersky_Internet_Security_06_EN.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/52e58516e570a3da6c7bbd0c77a1c41a/41099/Kaspersky_Internet_Security_06_EN.jpg",
              "alt": "Kaspersky 2013",
              "title": "Kaspersky 2013",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: If you done correctly you should be able to see your cameras IP in the list of trusted URLs. Next please confirm all settings with OK.`}</p>
    <p><strong parentName="p">{`Step 7`}</strong>{`: Finally please reload the web interface of your IP camera. This can be done by either clicking the refresh button next to the address bar of your web browser or you can close your web browser and type in the IP address of your IP camera again.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      